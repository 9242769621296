<template>
    <vx-card :title="'View Item Requested (' + this.status + ')'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Canvass Item Request Document</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="code" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Canvass Handover Document</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="handoverCode ? handoverCode : 'Not yet'" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Territory</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedTerritory ? selectedTerritory.label : ''" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedWarehouse ? selectedWarehouse.label : ''" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Sales</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedSales ? selectedSales.label : ''" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Request Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="requestDate" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Reason</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-textarea v-model="reason" class="w-full" :readonly="this.approvalStatus != 0"></vs-textarea>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <h4>Requested Items</h4>
                <hr>
                <br>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="60%">SKU Description</th>
                            <th width="10%">Qty</th>
                            <th width="20%">HU</th>
                            <th width="10%">Qty UOM</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(skuLine, index) in skuLines" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="skuLine.label" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLine.qty" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="skuLine.unit" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLine.qty_uom" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6" v-if="handoverLines.length > 0">
            <div class="vx-col w-full">
                <h4>Handover Items</h4>
                <hr>
                <br>
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="40%">SKU Description</th>
                            <th width="7%">Qty</th>
                            <th width="10%">HU</th>
                            <th width="7%">Qty UOM</th>
                            <th width="15%">Batch</th>
                            <th width="5%">Warehouse Handover</th>
                            <th width="5%">Sales Handover</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(skuLine, index) in handoverLines" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="skuLine.label" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLine.qty" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="skuLine.unit" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="skuLine.qty_uom" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input :value="skuLine.batch" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-checkbox v-model="skuLine.warehouse_handover" disabled></vs-checkbox>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-checkbox v-model="skuLine.sales_handover" disabled></vs-checkbox>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="flex w-full justify-center">
            <vs-button class="ml-4 mt-2" type="border" color="success" @click="handleDecision('approve')" v-if="this.approvalStatus == 0">Approve</vs-button>
            <vs-button class="ml-4 mt-2" type="border" color="danger" @click="handleDecision('reject')" v-if="this.approvalStatus == 0">Reject</vs-button>
            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="handleDecision('inquiry')" v-if="this.approvalStatus == 0">Inquiry</vs-button>
            <vs-button class="ml-4 mt-2" color="danger" v-on:click="handleBack">Back</vs-button>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";

export default {
    components: {},
    data() {
        return {
            id: null,
            readonly: true,
            approvalStatus: 0,
            status: "Loading...",
            code: null,
            handoverCode: null,
            selectedWarehouse: null,
            selectedTerritory: null,
            selectedSales: null,
            requestDate: null,
            reason: "",
            skuLines: [
                {
                    line_id: 0,
                    sku_code: null,
                    item_name: null,
                    label: null,
                    unit: null,
                    qty: 0,
                    qty_uom: 0,
                }
            ],
            handoverLines: [
                {
                    line_id: 0,
                    sku_code: null,
                    item_name: null,
                    label: null,
                    unit: null,
                    qty: 0,
                    qty_uom: 0,
                    batch: null,
                    warehouse_handover: null,
                    sales_handover: null,
                }
            ],
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http.get("/api/v1/canvas/detail/" + this.id)
            .then((resp) => {
                if (resp.code == 200) {
                    this.code = resp.data.request_code
                    this.handoverCode = resp.data.handover_code
                    this.approvalStatus = resp.data.approval_status
                    this.selectedTerritory = resp.data.territories
                    this.selectedWarehouse = resp.data.warehouses
                    this.selectedSales = resp.data.sales
                    this.requestDate = resp.data.request_date
                    this.reason = resp.data.reason
                    this.skuLines = resp.data.sku_lines
                    this.handoverLines = resp.data.handover_lines
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    this.handleBack()
                }

                switch (this.approvalStatus) {
                    case 0:
                        this.status = "Waiting Approval"
                        break
                    case 1:
                        this.status = "Approved"
                        break
                    case 2:
                        this.status = "Rejected"
                        break
                    case 11:
                        this.status = "Inquiry"
                        break
                    default :
                        break
                }
            })
            .catch(error => {
                this.$vs.loading();
                console.log(error)
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: error,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                this.handleBack()
            });
        },
        handleBack() {
            let tabOrder
            switch (this.approvalStatus) {
                case 0:
                    tabOrder = 0
                    break
                case 11:
                    tabOrder = 1
                    break
                case 1:
                    tabOrder = 2
                    break
                case 2:
                    tabOrder = 3
                    break
                default:
                    break
            }
            this.$router.push({
                name: "Canvas Item Request",
                params: { tab: tabOrder },
            });
        },
        handleDecision(type) {
            if (type != "approve") {
                if (this.reason == "") {
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: "Please fill the reason column",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });

                    return false
                }
            }

            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Please Confirm`,
                text: "Are you sure to "+type+" this document?",
                accept: () => {
                    this.$vs.loading();
                    let param = {
                        reason: this.reason,
                        type: type,
                    }

                    this.$http.post("/api/v1/canvas/single-decision/" + this.id, param)
                    .then(resp => {
                        if (resp.code == 200) {
                            this.$vs.loading.close();
                            this.$vs.notify({
                                color: "success",
                                title: "Success",
                                text: "The status of this document has been updated",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                            this.handleBack()
                        } else {
                            this.$vs.loading.close();
                            this.$vs.notify({
                                color: "danger",
                                title: "Error",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                        }
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: error,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    });
                }
            })
        },
    },
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>